<template>
  <validation-observer ref="formValidation">
    <b-form class="auth-login-form mt-2" @submit.prevent>

      <b-row
        v-for="(item, index) in order.additional_features"
        :id="`item-${index}`"
        :key="index"
      >
        <!-- Item Name -->
        <b-col lg="4" md="4">
          <validation-provider
            #default="{ errors }"
            :name="`Compra ${index+1}`"
            rules="required"
          >
            <b-form-group
              label="Nombre del extra *"
              label-for="item-name"
            >
              <b-form-input
                id="item-name"
                v-model="item.name"
                :state="errors.length > 0 ? false:null"
                :name="`Extra ${index+1}`"
                placeholder="Nombre del extra"
              />
            </b-form-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>


        </b-col>

        <b-col lg="4" md="4">
          <validation-provider
            #default="{ errors }"
            :name="`Precio extra ${index+1}`"
            :rules="{
              required: true,
              regex: /^\s*(?=.*[0-9])\d*(?:\.\d{1,2})?\s*$/,
            }"
          >
            <b-form-group
              label="Precio del extra *"
              label-for="item-price"
            >
              <b-form-input
                id="item-price"
                v-model="item.price"
                :state="errors.length > 0 ? false:null"
                :name="`Precio extra ${index+1}`"
                placeholder="Precio del extra"
              />
              <small class="text-danger" v-if="item.petty_cash">
                Pagar de caja chica: {{ item.expense_petty_cash }}
              </small>
            </b-form-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-col>

        <b-col lg="4" md="4">
          <validation-provider
            #default="{ errors }"
            :name="`Deposito ${index+1}`"
            :rules="{
              min:1,
              regex: /^\s*(?=.*[0-9])\d*(?:\.\d{1,2})?\s*$/,
            }"
          >
            <b-form-group
              v-if="order.distributor"
              label="Depositado al mensajero"
              label-for="deposit"
            >
              <b-form-input
                id="deposit"
                readonly
                v-model="item.deposit"
                :name="`Deposito ${index+1}`"
                placeholder="Depositado al mensajero"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>
      <hr>
      <div class="text-right">
        <b-overlay
          :show="overlay"
          rounded
          opacity="0.6"
          spinner-small
          spinner-variant="primary"
          class="d-inline-block"
        >
          <b-button
            type="submit"
            variant="gradient-primary"
            block
            @click="updateAddons()"
          >
            Guardar
          </b-button>
        </b-overlay>
      </div>

    </b-form>
  </validation-observer>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";

import {
  BFormGroup,
  BFormInput,
  BButton,
  BForm,
  BFormRadioGroup,
  BOverlay,
  BFormTextarea,
  BFormCheckbox,
  BCol,
  BRow
} from "bootstrap-vue";
import { required, min } from '@validations'
import OrderService from '@/services/order.service'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import {
  confirmAlert
} from '@/helpers'
import Swal from 'sweetalert2'

export default {
  props: ['order'],
  components: {
    BCol,
    BRow,
    BFormCheckbox,
    BFormTextarea,
    BFormGroup,
    BFormInput,
    BButton,
    BForm,
    BFormRadioGroup,
    BOverlay,
    ValidationProvider,
    ValidationObserver
  },
  data() {
    return {
      required,
      min,
      overlay: false,
      disabled_button: false
    }
  },
  methods: {
    confirm(title) {
      return confirmAlert(title)
    },
    async updateAddons() {
      const isConfirm = await this.confirm(`Esta seguro de editar las compras #${this.order.code}`)
      if (!isConfirm.isConfirmed) return false

      try {
        this.overlay = true
        const addons = this.order.additional_features.map(i=>i)
        const { data:res } = await OrderService.updateAddons(
          {
            order_id: this.order.id,
            addons
          }
        )
        if (res.success) {
          this.$emit('updateAddons', res.data)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: res.message,
              icon: 'CheckIcon',
              variant: 'success',
            }
          })
        } else {
          Swal.fire({
            title: res.message,
            text: res.message,
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        }
      } catch (error) {
        Swal.fire({
          title: 'Ha ocurrido un error al actualizar las compras',
          text: '',
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
      }
      this.overlay = false
    }
  }
}
</script>
