var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"formValidation"},[_c('b-form',{staticClass:"auth-login-form mt-2",on:{"submit":function($event){$event.preventDefault();}}},[_vm._l((_vm.order.additional_features),function(item,index){return _c('b-row',{key:index,attrs:{"id":("item-" + index)}},[_c('b-col',{attrs:{"lg":"4","md":"4"}},[_c('validation-provider',{attrs:{"name":("Compra " + (index+1)),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Nombre del extra *","label-for":"item-name"}},[_c('b-form-input',{attrs:{"id":"item-name","state":errors.length > 0 ? false:null,"name":("Extra " + (index+1)),"placeholder":"Nombre del extra"},model:{value:(item.name),callback:function ($$v) {_vm.$set(item, "name", $$v)},expression:"item.name"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-col',{attrs:{"lg":"4","md":"4"}},[_c('validation-provider',{attrs:{"name":("Precio extra " + (index+1)),"rules":{
            required: true,
            regex: /^\s*(?=.*[0-9])\d*(?:\.\d{1,2})?\s*$/,
          }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Precio del extra *","label-for":"item-price"}},[_c('b-form-input',{attrs:{"id":"item-price","state":errors.length > 0 ? false:null,"name":("Precio extra " + (index+1)),"placeholder":"Precio del extra"},model:{value:(item.price),callback:function ($$v) {_vm.$set(item, "price", $$v)},expression:"item.price"}}),(item.petty_cash)?_c('small',{staticClass:"text-danger"},[_vm._v(" Pagar de caja chica: "+_vm._s(item.expense_petty_cash)+" ")]):_vm._e()],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-col',{attrs:{"lg":"4","md":"4"}},[_c('validation-provider',{attrs:{"name":("Deposito " + (index+1)),"rules":{
            min:1,
            regex: /^\s*(?=.*[0-9])\d*(?:\.\d{1,2})?\s*$/,
          }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [(_vm.order.distributor)?_c('b-form-group',{attrs:{"label":"Depositado al mensajero","label-for":"deposit"}},[_c('b-form-input',{attrs:{"id":"deposit","readonly":"","name":("Deposito " + (index+1)),"placeholder":"Depositado al mensajero"},model:{value:(item.deposit),callback:function ($$v) {_vm.$set(item, "deposit", $$v)},expression:"item.deposit"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1):_vm._e()]}}],null,true)})],1)],1)}),_c('hr'),_c('div',{staticClass:"text-right"},[_c('b-overlay',{staticClass:"d-inline-block",attrs:{"show":_vm.overlay,"rounded":"","opacity":"0.6","spinner-small":"","spinner-variant":"primary"}},[_c('b-button',{attrs:{"type":"submit","variant":"gradient-primary","block":""},on:{"click":function($event){return _vm.updateAddons()}}},[_vm._v(" Guardar ")])],1)],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }