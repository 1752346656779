<template>
  <div>
    <div id="pdf" v-if="pdfSrc != ''" style="position:relative;height:80%">
      <object
        :data="pdfSrc"
        type="application/pdf"
        :width="width"
        :height="height"
      >
        <embed
          id="pdfEmbed"
          :src="pdfSrc"
          type="application/pdf"
          :width="width"
          :height="height"
        />
      </object>
    </div>
  </div>
</template>

<script>
export default {
  name: "pdf-viewer",
  props: ['src', 'width', 'height'],
  computed: {
    pdfSrc() {
      var file = new Blob([this.src],{ type: "application/pdf" });
      var urlPdf = URL.createObjectURL(file);
      return urlPdf + "#zoom=100#filename=hola";
    }
  }
};
</script>

<style scope></style>
