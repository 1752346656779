<template>
  <validation-observer ref="formValidation">
    <b-form
      class="auth-login-form mt-2"
      @submit.prevent
    >

      <div class="d-flex justify-content-between">
        <h4>Agregar compras a la orden {{ order ? '#'+order.code : '' }}</h4>

        <b-overlay
          :show="loading_money_distributor"
          rounded
          opacity="0.6"
          spinner-small
          spinner-variant="primary"
          class="d-inline-block"
        >
          <h5 class="text-success text-right">
            Dinero que anda el repartidor: {{ formatCurrency(money_distributor) }}
          </h5>
          <h5 v-if="expense_money_distributor > 0" class="text-danger text-right">
            Dinero a usar de caja chica: {{ formatCurrency(expense_money_distributor) }}
          </h5>
        </b-overlay>
      </div>

      <hr>
      <b-row
        v-for="(item, index) in addons"
        :id="`item-${index}`"
        :key="index"
      >
        <!-- Item Name -->
        <b-col lg="4" md="4">
          <validation-provider
            #default="{ errors }"
            :name="`Compra ${index+1}`"
            rules="required"
          >
            <b-form-group
              label="Nombre de la compra *"
              label-for="item-name"
            >
              <b-form-input
                id="item-name"
                v-model="item.name"
                :state="errors.length > 0 ? false:null"
                :name="`Compra ${index+1}`"
                placeholder="Nombre de la compra"
              />
            </b-form-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>


        </b-col>

        <b-col lg="4" md="4">
          <validation-provider
            #default="{ errors }"
            :name="`Precio compra ${index+1}`"
            :rules="{
              required: true,
              regex: /^\s*(?=.*[0-9])\d*(?:\.\d{1,2})?\s*$/,
            }"
          >
            <b-form-group
              label="Precio de la compra *"
              label-for="item-price"
            >
              <b-form-input
                id="item-price"
                :readonly="item.petty_cash"
                v-model="item.price"
                :state="errors.length > 0 ? false:null"
                :name="`Precio compra ${index+1}`"
                placeholder="Precio del compra"
                @keyup="disabledButton(item, index)"
              />
            </b-form-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-col>

        <b-col lg="4" md="4" class="d-flex align-items-center">
          <validation-provider
            #default="{ errors }"
            :name="`Deposito compra ${index+1}`"
            :rules="{
              min:1,
              regex: /^\s*(?=.*[0-9])\d*(?:\.\d{1,2})?\s*$/,
            }"
          >
            <b-form-group
              v-if="order.distributor"
              label="Depositado al mensajero"
              label-for="deposit"
            >
              <b-form-input
                id="deposit"
                :readonly="item.price <= 0 || item.petty_cash"
                v-model="item.deposit"
                :name="`Deposito ${index+1}`"
                placeholder="Depositado al mensajero"
                @keyup="disabledButton(item, index)"
              />
              <small class="text-danger">{{ errors[0] }}</small>
              <small v-if="item.invalid" class="text-danger">
                El monto del deposito no puede ser menor al precio de la compra
              </small>

              <div class="w-100 mt-1">
                <b-form-checkbox
                  :disabled="item.price <= 0"
                  v-model="item.petty_cash"
                  class="custom-control-primary"
                  @change="usedMoneyPettyCash(item, index)"
                >
                  Usar dinero de caja chica
                </b-form-checkbox>
              </div>
            </b-form-group>
          </validation-provider>

          <b-button
            v-if="addons.length > 1"
            variant="outline-danger"
            class="ml-2"
            size=sm
            @click="removeItem(item, index)"
          >
            <feather-icon
              icon="XIcon"
              class="mr-25"
            />
          </b-button>

        </b-col>

        <b-col cols="12">
          <hr>
        </b-col>
      </b-row>

      <div class="row">
        <div class="col">
          <b-button
            variant="primary"
            @click="repeateAgain"
            class="mb-2"
            :disabled="disabled_button"
          >
            <feather-icon
              icon="PlusIcon"
              class="mr-25"
            />
            <span>Agregar otro</span>
          </b-button>
        </div>
        <div class="col">
          <h4 class="text-success text-right">
            Total en compras: {{ formatCurrency(totalAddons) }}
          </h4>
        </div>
      </div>
      <hr>
      <div class="text-right">
        <b-overlay
          :show="overlay"
          rounded
          opacity="0.6"
          spinner-small
          spinner-variant="primary"
          class="d-inline-block"
        >
          <b-button
            type="submit"
            variant="gradient-primary"
            block
            @click="storeAddon"
            :disabled="disabled_button"
          >
            Guardar
          </b-button>
        </b-overlay>
      </div>

    </b-form>
  </validation-observer>
</template>

<script>
import {
  BFormGroup,
  BFormInput,
  BButton,
  BForm,
  BOverlay,
  BRow,
  BCol,
  BFormCheckbox
} from 'bootstrap-vue'

import {
  currencyFormat
} from "@/helpers";

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, min } from '@validations'
import OrderService from '@/services/order.service'
import Swal from 'sweetalert2'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  props: ['order', 'money_distributor', 'loading_money_distributor'],
  components: {
    BFormCheckbox,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    BForm,
    BOverlay,
    ValidationProvider,
    ValidationObserver
  },
  data() {
    return {
      required,
      min,
      overlay: false,
      disabled_button: false,
      expense_money_distributor: 0,

      addons: [
        { name: '', price: 0, deposit: 0, petty_cash: false, invalid: false }
      ]
    }
  },
  computed: {
    totalAddons() {
      let total = this.addons.map(i => {
        return parseFloat(i.price)
      }).reduce((a, b) => a + b)

      return total
    }
  },
  methods: {
    usedMoneyPettyCash(item, index) {
      if (item.petty_cash) {
        if (item.price > 0) {

          const resta = this.money_distributor - this.expense_money_distributor

          if ((item.price - item.deposit) <= 0) {
            Swal.fire({
              title: 'No es necesario pagar con caja chica',
              text: 'El monto ingresado en deposito cubre el valor de la compra',
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
            this.addons[index].petty_cash = false
            return false
          }

          if ((item.price - item.deposit) > resta) {
            Swal.fire({
              title: 'No se puede pagar este extra solo con el dinero de caja chica',
              text: 'Deposita al repartidor la diferencia',
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
            this.addons[index].petty_cash = false
            return false
          }

          this.expense_money_distributor += item.price - item.deposit
          this.addons[index].invalid = false
          this.disabled_button = false
        }
      } else {
        this.expense_money_distributor -= item.price - item.deposit
      }
    },
    formatCurrency(amount) {
      return currencyFormat(amount);
    },
    disabledButton(item, index) {
      if (!item.petty_cash) {
        if (item.price > 0) {
          let disabled_btn = false
          if (parseFloat(item.deposit) < parseFloat(item.price)) {
            disabled_btn = true
          }
          this.addons[index].invalid = disabled_btn
          this.disabled_button = disabled_btn
        } else {
          this.addons[index].deposit = 0
          this.disabled_button = false
        }
        this.addons = JSON.parse(JSON.stringify(this.addons))
      }
    },
    storeAddon() {
      this.$refs.formValidation.validate().then(async success => {
        if (success) {

          try {
            this.overlay = true
            const { data:res } = await OrderService.storeAddon(
              {
                order_id: this.order.id,
                addons: this.addons
              }
            )
            if (res.success) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: res.message,
                  icon: 'CheckIcon',
                  variant: 'success',
                },
              })
              this.$emit('addAddonToOrder', res.data)
              this.addons = [
                { name: '', price: '' }
              ]
            } else {
              Swal.fire({
                title: 'Ha ocurrido un error al registrar el extra',
                text: res.message,
                icon: 'error',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            }
            this.overlay = false
          } catch (error) {
            this.overlay = false
            Swal.fire({
              title: 'Ha ocurrido un error al registrar el extra',
              text: '',
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
          }

        }
      })
    },
    repeateAgain() {
      this.addons.push({
        name: '', price: '', deposit: 0, invalid: false, petty_cash: false
      })
    },
    removeItem(item, index) {
      if (item.petty_cash) {
        this.expense_money_distributor -= item.price - item.deposit
      }
      this.addons.splice(index, 1)
    }
  }
}

</script>
