<template>
  <validation-observer ref="formValidation">
    <b-form class="auth-login-form mt-2" @submit.prevent>
      <div class="row" v-if="order != 'all'">
        <div class="col-12">
          <label for="">¿Qué va a pagar?</label>
          <validation-provider
            #default="{ errors }"
            name="Tipo de pago"
            rules="required"
          >
            <small class="text-danger">{{ errors[0] }}</small>
            <b-form-radio-group
              v-model="payment.payment_type"
              :options="payment_types"
              class="demo-inline-spacing mb-1"
              text-field="text"
              value-field="value"
              disabled-field="disabled"
              name="Tipo de pago"
            />
          </validation-provider>
        </div>
      </div>

      <div v-if="payment.payment_type != ''">
        <p v-if="payment.payment_type == 'all'">
          Deuda total: {{ formatCurrency(order.due.due_total) }}
        </p>
        <p v-if="payment.payment_type == 'services'">
          Deuda en servicios: {{ formatCurrency(order.due.due_services) }}
        </p>
        <p v-if="payment.payment_type == 'addons'">
          Deuda en extras: {{ formatCurrency(order.due.due_addons) }}
        </p>
      </div>

      <hr />

      <div class="row">
        <div class="col-12">
          <b-form-group label="Método de pago *" label-for="payment_method">
            <validation-provider
              #default="{ errors }"
              name="Método de pago"
              rules="required"
            >
              <small class="text-danger">{{ errors[0] }}</small>
              <v-select
                id="payment_method"
                v-model="payment.payment_method"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="
                  $store.state.app.payment_methods.filter(
                    i => i.hacienda !== true
                  )
                "
                :reduce="payment_method => payment_method.id"
                :clearable="false"
                class="per-page-selector"
                label="name"
                name="Método de pago"
              />
            </validation-provider>
          </b-form-group>

          <div v-if="payment.payment_method == 8 && order != 'all'">
            <b-form-checkbox v-model="payment.distributor">
              El dinero lo tiene el repartidor
            </b-form-checkbox>
            <hr />
          </div>
        </div>
      </div>

      <div class="row" v-if="order != 'all'">
        <div class="col-12">
          <b-form-group label="Monto *" label-for="amount">
            <validation-provider
              #default="{ errors }"
              name="Monto"
              :rules="{
                required: true,
                regex: /^\s*(?=.*[0-9])\d*(?:\.\d{1,2})?\s*$/
              }"
            >
              <b-form-input
                id="amount"
                v-model="payment.amount"
                :state="errors.length > 0 ? false : null"
                name="Monto"
                placeholder="Monto"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </div>
      </div>

      <div class="row" v-if="order != 'all'">
        <div class="col-12">
          <b-form-group label="Descripción" label-for="description">
            <validation-provider
              #default="{ errors }"
              name="Descripción"
              rules="required"
            >
              <b-form-input
                readonly
                disabled
                id="description"
                v-model="payment.description"
                name="Descripción"
                placeholder="Descripción"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <b-form-group label="Fecha">
            <validation-provider
              #default="{ errors }"
              name="Fecha"
              rules="required"
            >
              <date-picker
                range-separator="-"
                v-model="payment.date"
                append-to-body
                lang="es"
                format="YYYY-MM-DD"
                style="width: 100%"
                clearable
                name="Fecha"
              ></date-picker>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <b-form-group label="Referencia" label-for="reference">
            <b-form-input
              id="reference"
              v-model="payment.reference"
              name="Referencia"
              placeholder="Referencia"
            />
          </b-form-group>
        </div>
      </div>

      <div class="text-right">
        <b-overlay
          :show="overlay"
          rounded
          opacity="0.6"
          spinner-small
          spinner-variant="primary"
          class="d-inline-block"
        >
          <b-button
            type="submit"
            variant="gradient-primary"
            block
            @click="storePayment"
          >
            Guardar
          </b-button>
        </b-overlay>
      </div>
    </b-form>
  </validation-observer>
</template>

<script>
import {
  BFormGroup,
  BFormInput,
  BButton,
  BForm,
  BFormRadioGroup,
  BOverlay,
  BFormTextarea,
  BFormCheckbox
} from "bootstrap-vue";
import vSelect from "vue-select";

import { currencyFormat } from "@/helpers";

import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import PaymentService from "@/services/payments.service";
import Swal from "sweetalert2";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import DatePicker from "vue2-datepicker";

export default {
  props: ["order", "rangeDates", "clientId"],
  components: {
    BFormCheckbox,
    DatePicker,
    vSelect,
    BFormTextarea,
    BFormGroup,
    BFormInput,
    BButton,
    BForm,
    BFormRadioGroup,
    BOverlay,
    ValidationProvider,
    ValidationObserver
  },
  data() {
    return {
      payment_types: [
        { text: "Servicios", value: "services" },
        { text: "Extras", value: "addons" },
        { text: "Todo", value: "all" }
      ],
      required,
      overlay: false,

      payment: {
        distributor: false,
        order_id: "",
        payment_method: "",
        amount: "",
        date: "",
        description: "",
        reference: "",
        payment_type: ""
      }
    };
  },
  watch: {
    "payment.payment_type": {
      handler(newval) {
        if (newval == "all") {
          this.payment.amount = this.order.due.due_total;
          this.payment.description = `Pago total de orden #${this.order.id}`;
        } else {
          if (newval != "") {
            this.payment.amount = "";
            const type = this.payment_types.findIndex(i => i.value == newval);
            this.payment.description = `Pago de ${this.payment_types[type].text} de orden #${this.order.id}`;
          }
        }
      },
      deep: true
    }
  },
  methods: {
    async storePayment() {
      this.$refs.formValidation.validate().then(async success => {
        if (success) {
          try {
            this.overlay = true;
            this.payment.order_id = this.order.id;
            const { data: res } =
                (this.order) == "all"
                ? await PaymentService.storePaymentAll({
                    ...this.payment,
                    range_dates: this.rangeDates,
                    client_id: this.clientId
                  })
                : await PaymentService.storePayment(this.payment);
            if (res.success) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: res.message,
                  icon: "CheckIcon",
                  variant: "success"
                }
              });
              this.$emit("payOrder", res.data);
              this.payment = { ...this.$options.data().payment };
            } else {
              Swal.fire({
                title: "Ha ocurrido un error al registrar el pago",
                text: res.message,
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary"
                },
                buttonsStyling: false
              });
            }
            this.overlay = false;
          } catch (error) {
            console.log(error);
            this.overlay = false;
            Swal.fire({
              title: "Ha ocurrido un error al registrar el pago",
              text: "",
              icon: "error",
              customClass: {
                confirmButton: "btn btn-primary"
              },
              buttonsStyling: false
            });
          }
        }
      });
    },

    formatCurrency(amount) {
      return currencyFormat(amount);
    }
  }
};
</script>
