<template>
  <!-- Table Container Card -->
  <b-overlay :show="loading" rounded="sm">
    <b-card no-body>
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="9"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <div>
              <label for="">Por página</label>
              <v-select
                v-model="perPage"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector mr-1"
              />
            </div>

            <div>
              <label for="">Entre fechas</label> <br />
              <date-picker
                range-separator="-"
                v-model="rangePicker"
                :default-value="rangePicker"
                range
                append-to-body
                lang="es"
                format="YYYY-MM-DD"
                style="width: 200px"
                class="mr-1"
                :clearable="false"
              ></date-picker>
            </div>

            <div>
              <label for="">Repartidor</label>
              <v-select
                v-model="distributorFilter"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="distributorsFilters"
                :clearable="false"
                label="text"
                style="width:170px;margin-right: 1rem"
              />
            </div>

            <div>
              <label for="">Estado</label>
              <v-select
                v-model="statusFilter"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="copy_statuses"
                :clearable="false"
                label="name"
                style="width:170px;margin-right: 1rem"
              />
            </div>

            <div class="mt-2">
              <b-button variant="success" class="btn-icon mr-1" @click="get">
                <feather-icon icon="RefreshCwIcon" size="16" />
              </b-button>
            </div>

            <div class="mt-2">
              <b-button
                v-if="
                  (user_state && user_state.role == 'admin') ||
                    permissions.includes('create_order')
                "
                @click="createOrder"
                variant="gradient-info"
                v-b-toggle.sidebar-1
              >
                <feather-icon icon="PlusIcon" size="16" />
                Nueva orden
              </b-button>
            </div>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="3">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="search"
                class="d-inline-block mr-1"
                placeholder="Buscar..."
              />
            </div>
          </b-col>
        </b-row>
      </div>

      <div class="table-responsive">
        <b-table
          ref="refInvoiceListTable"
          :items="items"
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          empty-text="No se encontraron registros"
          :sort-desc.sync="isSortDirDesc"
          class="position-relative"
        >
          <template #head(actions)="data">
            <feather-icon
              icon="ToolIcon"
              class="cursor-pointer mr-1"
              size="18"
            />
          </template>

          <!-- created_at -->
          <template #cell(code)="data">
            <router-link
              v-b-tooltip.hover.top="`${data.item.description}`"
              :to="{ name: 'order-detail', params: { id: data.item.id } }"
            >
              # {{ data.item.code }}
            </router-link>
          </template>

          <!-- created_at -->
          <template #cell(created_at)="data">
            <template v-if="data.item.scheduled">
              <span
                class="font-weight-bold d-block text-nowrap"
                style="position:relative"
              >
                <i
                  v-if="data.item.order_latest_status.code === 'scheduled'"
                  class="fa fa-clock text-warning"
                  style="position:absolute;left:-17px;top:3px"
                ></i>
                {{ dateFormat(data.item.scheduled_date, "DD-MM-YYYY") }}
              </span>
              <small class="text-muted">
                {{ dateFormat(data.item.scheduled_date, "hh:mm A") }}
              </small>
            </template>
            <template v-else>
              <span class="font-weight-bold d-block text-nowrap">
                {{ dateFormat(data.item.created_at, "DD-MM-YYYY") }}
              </span>
              <small class="text-muted">
                {{ dateFormat(data.item.created_at, "hh:mm A") }}
              </small>
            </template>
          </template>

          <!-- client -->
          <template #cell(client)="data">
            <span class="font-weight-bold d-block text-nowrap">
              {{ data.item.client.full_name }}
            </span>
            <small class="text-muted">
              {{ data.item.client.email }}
            </small>
          </template>

          <template #cell(distributor)="data">
            <span class="font-weight-bold d-block text-nowrap">
              {{
                data.item.distributor
                  ? data.item.distributor.user.full_name
                  : "S/R"
              }}
            </span>
            <small v-if="data.item.vehicle">
              Vehículo: {{ data.item.vehicle }}
            </small>
          </template>

          <template #cell(order_latest_status)="data">
            <b-overlay
              :show="loading_btn_change_status == data.item.id"
              rounded
              opacity="0.6"
              spinner-small
              spinner-variant="primary"
              class="d-inline-block"
            >
              <b-dropdown
                id="dropdown-1"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                :text="data.item.order_latest_status.name"
                :variant="getColorStatus(data.item.order_latest_status.code)"
                size="sm"
                :disabled="
                  disabledActionsToOrder(data.item.order_latest_status.code) ||
                    (!(user_state && user_state.role == 'admin') &&
                      !permissions.includes('change_status_order'))
                "
              >
                <b-dropdown-item
                  v-for="(status, i) in statuses"
                  :key="i"
                  @click="changeStatusOrder(data.item.id, status.id)"
                  :disabled="data.item.last_status_id == status.id"
                >
                  {{ status.name }}
                </b-dropdown-item>
              </b-dropdown>
            </b-overlay>
          </template>

          <template #cell(total)="data">
            {{ formatCurrency(data.item.total) }}
          </template>

          <template #cell(additional_total)="data">
            {{ formatCurrency(data.item.additional_total) }}
          </template>

          <template #cell(due)="data">
            <div class="d-flex align-items-center">
              <span class="font-weight-bolder" style="margin-right: 5px">
                {{ formatCurrency(data.item.due.due_total) }}
              </span>
              <feather-icon icon="TrendingDownIcon" class="text-danger" />
            </div>
          </template>

          <template #cell(total_paid)="data">
            <div class="d-flex align-items-center">
              <span class="font-weight-bolder" style="margin-right: 5px">
                {{ formatCurrency(data.item.total_paid) }}
              </span>
              <feather-icon icon="TrendingUpIcon" class="text-success" />
            </div>
          </template>

          <!-- Column: Actions -->
          <template #cell(actions)="data">
            <b-overlay
              :show="loading_option_order == data.item.id"
              rounded
              opacity="0.6"
              spinner-small
              spinner-variant="primary"
              class="d-inline-block"
            >
              <div class="text-nowrap text-center ml-2">

                <b-dropdown variant="link" toggle-class="p-0" no-caret dropup>
                  <template #button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="20"
                      class="text-body"
                    />
                  </template>

                  <template>
                    <b-dropdown-item
                      @click="
                        $router.push({
                          name: 'order-detail',
                          params: { id: data.item.id }
                        })
                      "
                    >
                      <feather-icon icon="EyeIcon" />
                      <span class="align-middle ml-50">Ver orden</span>
                    </b-dropdown-item>

                    <b-dropdown-item
                      v-if="data.item.additional_features.length"
                      @click="viewAddons(data.item)"
                    >
                      <feather-icon icon="TagIcon" />
                      <span class="align-middle ml-50">Ver extras</span>
                    </b-dropdown-item>

                    <b-dropdown-item
                      @click="editOrder(data.item)"
                      v-if="
                        (user_state && user_state.role == 'admin') ||
                          permissions.includes('edit_order')
                      "
                    >
                      <feather-icon icon="EditIcon" />
                      <span class="align-middle ml-50">Editar</span>
                    </b-dropdown-item>

                    <b-dropdown-item
                      :disabled="
                        disabledActionsToOrder(
                          data.item.order_latest_status.code
                        )
                      "
                      v-if="
                        !data.item.distributor &&
                          ((user_state && user_state.role == 'admin') ||
                            permissions.includes('assign_distributor_order'))
                      "
                      @click="addDistributor(data.item.id)"
                    >
                      <feather-icon icon="UserPlusIcon" />
                      <span class="align-middle ml-50">Asignar Repartidor</span>
                    </b-dropdown-item>

                    <b-dropdown-item
                      :disabled="
                        disabledActionsToOrder(
                          data.item.order_latest_status.code
                        )
                      "
                      v-if="
                        data.item.distributor &&
                          ((user_state && user_state.role == 'admin') ||
                            permissions.includes('remove_distributor_order'))
                      "
                      @click="
                        removeDistributorToOrder(
                          data.item.id,
                          data.item.distributor.id
                        )
                      "
                    >
                      <feather-icon icon="UserMinusIcon" />
                      <span class="align-middle ml-50">Quitar Repartidor</span>
                    </b-dropdown-item>

                    <b-dropdown-item
                      :disabled="
                        disabledActionsToOrder(
                          data.item.order_latest_status.code
                        )
                      "
                      v-if="
                        (user_state && user_state.role == 'admin') ||
                          permissions.includes('add_additional_features_order')
                      "
                      @click="openModalAddonForm(data.item)"
                    >
                      <feather-icon icon="PlusIcon" />
                      <span class="align-middle ml-50">Agregar extras</span>
                    </b-dropdown-item>
                  </template>

                  <b-dropdown-item
                    :disabled="
                      disabledActionsToOrder(data.item.order_latest_status.code)
                    "
                    v-if="
                      (user_state && user_state.role == 'admin') ||
                        permissions.includes('download_receipt_order')
                    "
                    @click="printOrderReceipt(data.item.id, data.item.code)"
                  >
                    <feather-icon icon="FileTextIcon" />
                    <span class="align-middle ml-50">Descargar recibo</span>
                  </b-dropdown-item>
                  <b-dropdown-item
                    v-if="
                      !data.item.converted &&
                        data.item.order_latest_status.code == 'finished' &&
                        ((user_state && user_state.role == 'admin') ||
                          permissions.includes(
                            'create_electronic_invoice_order'
                          ))
                    "
                    @click="
                      () =>
                        $router.push({
                          path: '/create-invoice',
                          query: { order: data.item.id }
                        })
                    "
                  >
                    <feather-icon icon="FileTextIcon" />
                    <span class="align-middle ml-50">Emitir factura</span>
                  </b-dropdown-item>

                  <template
                    v-if="
                      (!['cancelled', 'scheduled'].includes(
                        data.item.order_latest_status.code
                      ) &&
                        !data.item.paid &&
                        user_state &&
                        user_state.role == 'admin') ||
                        permissions.includes('pay_order')
                    "
                  >
                    <b-dropdown-item @click="openPaymentForm(data.item)">
                      <feather-icon icon="DollarSignIcon" />
                      <span class="align-middle ml-50">Pagar</span>
                    </b-dropdown-item>
                  </template>
                </b-dropdown>
              </div>
            </b-overlay>
          </template>
        </b-table>
      </div>

      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted" v-if="totalRegisters > 0"
              >Mostrando {{ fromRegister }} a {{ toRegister }} de
              {{ totalRegisters }} registros
            </span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-if="items.length !== totalRegisters"
              v-model="currentPage"
              :total-rows="totalRegisters"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>

        <!--
        <div class="pt-1" v-if="checkItems.length > 0">
          <b-button
            v-if="packageFilter.value === 'ready'"
            @click="openAddPackages"
            variant="primary"
          >
            <feather-icon icon="PlusIcon" size="16" />
            Agregar ordens seleccionado a saca
          </b-button>
          <b-button
            v-if="packageFilter.value === 'no_internal_tracking'"
            @click="generateInternalTracking"
            variant="primary"
          >
            <feather-icon icon="PlusIcon" size="16" />
            Generar guía para ordens seleccionados
          </b-button>
        </div>-->
      </div>
    </b-card>

    <b-sidebar
      :visible="showAddOrderModal"
      @hidden="showAddOrderModal = false"
      :title="`${flagUpdateAction ? 'Editar orden' : 'Registrar orden'}`"
      shadow
      bg-variant="white"
      backdrop
      width="40%"
      right
    >
      <div class="p-2">
        <form-order
          :order="orderData"
          :edit="flagUpdateAction"
          @formAction="formOrderAction"
        />
      </div>
    </b-sidebar>

    <b-modal
      v-model="modalAddDistributor"
      no-close-on-backdrop
      cancel-variant="secondary"
      hide-footer
      centered
      size="md"
      title="Asignar repartidor"
    >
      <div class="p-1">
        <label>Selecciona un repartidor</label>
        <v-select
          v-model="distributorSelectForAsign.user"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :options="$store.state.app.distributors"
          :clearable="false"
          :reduce="distributor => distributor.id"
          class="per-page-selector mr-1"
          label="full_name"
          style="width: 100%"
        />

        <div class="text-right mt-2">
          <b-overlay
            :show="loading_assign_distributor"
            rounded
            opacity="0.6"
            spinner-small
            spinner-variant="primary"
            class="d-inline-block"
          >
            <b-button
              variant="gradient-primary"
              block
              @click="assignDistributorToOrder"
            >
              Asignar
            </b-button>
          </b-overlay>
        </div>
      </div>
    </b-modal>

    <b-modal
      id="modal-print-order-receipt"
      ok-only
      ok-title="Cerrar"
      centered
      size="lg"
      title="Imprimir recibo"
    >
      <b-overlay :show="loadingPdfReceipt" rounded="sm">
        <b-card-text>
          <PDFViewer
            :src="srcPdfReceipt"
            width="100%"
            height="100%"
            style="height: calc(100vh - 90px)"
          />
        </b-card-text>
      </b-overlay>
    </b-modal>

    <b-sidebar
      :visible="showPaymentForm"
      @hidden="showPaymentForm = false"
      shadow
      bg-variant="white"
      title="Pagar"
      right
      width="380px"
    >
      <b-card-text>
        <div class="p-2">
          <form-payment-order :order="orderPayment" @payOrder="eventToOrder" />
        </div>
      </b-card-text>
    </b-sidebar>

    <b-modal
      v-model="modalAddAddon"
      cancel-variant="secondary"
      hide-footer
      centered
      size="lg"
      title="Agregar extra a la orden"
    >
      <form-add-addon-to-order
        :order="orderPayment"
        :money_distributor="money_distributor"
        :loading_money_distributor="loading_money_distributor"
        @addAddonToOrder="eventToOrder"
      />
    </b-modal>

    <b-sidebar
      :visible="modalViewAddons"
      @hidden="modalViewAddons = false"
      shadow
      backdrop
      bg-variant="white"
      :title="`Editar compras de orden #${orderPayment ? orderPayment.code : ''}`"
      right
      width="50%"
    >
      <b-card-text>
        <div class="p-2">
          <form-update-addons :order="orderPayment" @updateAddons="updateAddons" />
        </div>
      </b-card-text>
    </b-sidebar>

  </b-overlay>
</template>

<script>
import {
  BCard,
  BCardText,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BTooltip,
  BModal,
  BForm,
  BFormSelect,
  BFormCheckbox,
  BPopover,
  BOverlay,
  BSidebar,
  VBTooltip
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import { onMounted, ref, watch, onUnmounted } from "@vue/composition-api";
import useInvoicesList from "./apps/invoice/invoice-list/useInvoiceList";
import { useToast } from "vue-toastification/composition";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

//Validation forms
import { ValidationProvider, ValidationObserver } from "vee-validate";

//Services
import orderService from "@/services/order.service";
import clientService from "@/services/client.service";
import utilitiesService from "@/services/utilities.service";

//para registrar y editar orden
import FormOrder from "@/components/FormOrder";

import DatePicker from "vue2-datepicker";

//Helpers
import {
  confirmAlert,
  perPageBaseOptions,
  perPageBaseRegisters,
  currencyFormat,
  downloadBlob
} from "@/helpers";
import moment from "moment";
moment.locale("es");

import StatusPackage from "@/components/StatusPackage";
import Swal from "sweetalert2";
import PDFViewer from "@/components/PdfViewer";
import FormPaymentOrder from "@/components/FormPaymentOrder";
import FormAddAddonToOrder from "@/components/FormAddAddonToOrder";
import FormUpdateAddons from "@/components/FormUpdateAddons";

import router from "@/router";

import Echo from "laravel-echo";
import config from "@/config.js";
import store from "@/store";
import { mapState } from "vuex";

export default {
  components: {
    FormUpdateAddons,
    DatePicker,
    FormAddAddonToOrder,
    FormPaymentOrder,
    PDFViewer,
    BCardText,
    FormOrder,
    BSidebar,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTooltip,
    vSelect,
    BModal,
    BFormGroup,
    BForm,
    BFormSelect,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
    BPopover,
    StatusPackage,
    BOverlay
  },
  directives: {
    Ripple,
    "b-tooltip": VBTooltip
  },
  computed: {
    ...mapState({
      user_state: state => state.app.user,
      permissions: state => state.app.permissions
    })
  },
  setup() {

    const money_distributor = ref(0);
    const loading_money_distributor = ref(false)

    const loadingPdfReceipt = ref(false);
    const srcPdfReceipt = ref(null);

    const distributorSelectForAsign = ref({
      user: "",
      order_id: ""
    });

    const rangePicker = ref([
      new Date(new Date().getFullYear(), new Date().getMonth(), 1),
      new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)
    ]);

    const toast = useToast();

    const loading = ref(true);
    const items = ref([]);
    const tableColumns = ref([
      { key: "created_at", label: "Fecha", sortable: true },
      { key: "code", label: "Código", sortable: true },
      { key: "client", label: "Cliente", sortable: true },
      { key: "distributor", label: "Repartidor", sortable: true },
      { key: "order_latest_status", label: "Estatus", sortable: true },
      { key: "actions", label: "Acciones", tdClass: "p-0 pr-1" },
      { key: "total", label: "Total", sortable: true },
      { key: "additional_total", label: "Extras", sortable: true },
      { key: "due", label: "Deuda", sortable: true },
      { key: "total_paid", label: "Pagado", sortable: true }
    ]);

    const distributorsFilters = ref([
      { text: "Todos", value: "all" },
      { text: "Con repartidor", value: "with" },
      { text: "Sin repartidor", value: "with_out" }
    ]);

    const statusFilter = ref({ name: "No finalizadas", id: "no_finished" });
    const distributorFilter = ref({ text: "Todos", value: "all" });
    const showAddOrderModal = ref(false);

    const flagUpdateAction = ref(false);

    const baseOrder = {
      vehicle: "",
      scheduled: false,
      date: "",
      hour: "",
      client: "",
      services: [
        {
          service: ""
        }
      ],
      distributor: {
        user: ""
      },
      pickup_address: "",
      delivery_address: "",
      description: "",
      electronic_invoice: false,
      note: ""
    };

    //Pagination
    const currentPage = ref(1);
    const lastPage = ref(1);
    const totalRegisters = ref(1);
    const search = ref("");
    const fromRegister = ref(1);
    const toRegister = ref(perPageBaseRegisters);
    const perPage = ref(perPageBaseRegisters);
    const perPageOptions = ref(perPageBaseOptions);
    const clients = ref([]);
    const statuses = ref([]);
    const copy_statuses = ref([]);
    const loading_btn = ref(false);
    const orderData = ref(baseOrder);
    const loading_btn_change_status = ref(false);
    const modalAddDistributor = ref(false);
    const loading_assign_distributor = ref(false);

    const orderPayment = ref("");
    const showPaymentForm = ref(false);
    const modalAddAddon = ref(false);

    const modalViewAddons = ref(false)

    //Methods
    const printOrderReceipt = async (orderId, orderCode) => {
      try {
        const { data: res } = await orderService.printOrderReceipt(orderId);
        return downloadBlob(res, `Orden #${orderCode}.pdf`);
      } catch (error) {
        loadingPdfReceipt.value = false;
      }
    };

    const addDistributor = order => {
      distributorSelectForAsign.value.order_id = order;
      modalAddDistributor.value = true;
    };

    const assignDistributorToOrder = async () => {
      loading_assign_distributor.value = true;
      const { data: res } = await orderService.assignDistributorToOrder(
        distributorSelectForAsign.value
      );
      if (res.success) {
        toast({
          component: ToastificationContent,
          props: {
            title: res.message,
            icon: "CheckIcon",
            variant: "success"
          }
        });
        const index = items.value.findIndex(i => i.id === res.data.order_id);
        items.value[index].distributor = res.data;
        items.value = JSON.parse(JSON.stringify(items.value));

        modalAddDistributor.value = false;
      } else {
        Swal.fire(res.message, "", "warning");
      }

      loading_assign_distributor.value = false;
    };

    const removeDistributorToOrder = async (orderId, distributorId) => {
      loading_option_order.value = orderId;
      const { data: res } = await orderService.removeDistributorToOrder({
        distributor_id: distributorId
      });
      if (res.success) {
        toast({
          component: ToastificationContent,
          props: {
            title: res.message,
            icon: "CheckIcon",
            variant: "success"
          }
        });
        const index = items.value.findIndex(i => i.id === res.data.order_id);
        items.value[index].distributor = null;
        items.value = JSON.parse(JSON.stringify(items.value));
      } else {
        Swal.fire(res.message, "", "warning");
      }

      loading_option_order.value = false;
    };

    const dateFormat = (date, format = "DD/MM/YYYY") => {
      return moment(date).format(format);
    };

    const formatCurrency = amount => {
      return currencyFormat(amount);
    };

    const createOrder = () => {
      orderData.value = JSON.parse(JSON.stringify(baseOrder));
      showAddOrderModal.value = true;
      flagUpdateAction.value = false;
    };

    const loading_option_order = ref(false);

    const editOrder = async item => {
      loading_option_order.value = item.id;
      const { data: res } = await orderService.getOrderDetail(item.id);
      if (!res.data.distributor) {
        res.data.distributor = { user: "" };
      }
      orderData.value = JSON.parse(JSON.stringify(res.data));

      if (res.data.scheduled && res.data.date) {
        orderData.value.date = new Date(res.data.date);
      }

      showAddOrderModal.value = true;
      flagUpdateAction.value = true;
      loading_option_order.value = false;
    };

    const deleteOrder = async orderId => {
      try {
        const confirm = await confirmAlert(
          "Eliminar orden",
          "Vas a eliminar el orden deseas continuar"
        );
        if (!confirm.isConfirmed) return;

        const { data: res } = await orderService.deleteOrder({
          id: orderId
        });

        const index = items.value.findIndex(i => i.id === orderId);
        items.value.splice(index, 1);
      } catch (error) {
        console.log(error);
      }
    };

    const changeStatusOrder = async (orderId, statusId) => {
      try {
        let text_motive = "";
        const canMotive = await Swal.fire({
          title: "Ingrese el motivo del cambio de estado de la orden",
          input: "text",
          inputAttributes: { autocapitalize: "off" },
          customClass: { confirmButton: "mr-1" },
          showCancelButton: true,
          confirmButtonText: "Continuar",
          cancelButtonText: "Cancelar",
          showLoaderOnConfirm: true,
          preConfirm: async motive => {
            if (motive) {
              text_motive = motive;
            } else {
              Swal.fire.showValidationMessage(`Ingresa un motivo`);
            }
          },
          allowOutsideClick: () => !Swal.isLoading()
        });

        if (canMotive.isConfirmed) {
          loading_btn_change_status.value = orderId;

          const { data: res } = await orderService.changeStatusOrder({
            order_id: orderId,
            status_id: statusId,
            motive: text_motive
          });

          if (res.success) {
            const indexOrder = items.value.findIndex(i => i.id == orderId);
            items.value[indexOrder].last_status_id =
              res.data.order.last_status_id;
            items.value[indexOrder].order_latest_status =
              res.data.order.order_latest_status;
            items.value = JSON.parse(JSON.stringify(items.value));

            if (res.data.electronic_invoice) {
              const confirm = await confirmAlert(
                "Esta orden fue marcada para generar factura electronica",
                "¿Desea generar la factura electronica inmediatamente?"
              );
              if (confirm.isConfirmed) {
                router.push({
                  path: "/create-invoice",
                  query: { order: res.data.order.id }
                });
              }
            }
          }

          loading_btn_change_status.value = false;
        }
      } catch (error) {
        loading_btn_change_status.value = false;
        Swal.fire({
          title: "Ocurrio un problema",
          text: "",
          icon: "warning"
        });
      }
    };

    const get = async (page = 1, searchQuery = {}) => {
      try {
        loading.value = true;
        searchQuery.perPage = perPage.value;
        searchQuery.filter = distributorFilter.value.value;
        searchQuery.status = statusFilter.value.id;
        searchQuery.daterange = [
          moment(rangePicker.value[0]).format("yyyy-MM-DD"),
          moment(rangePicker.value[1]).format("yyyy-MM-DD")
        ].join();

        const { data: res } = await orderService.getOrders(page, searchQuery);
        items.value = res.data.data;
        loading.value = false;
        //Pagination
        lastPage.value = res.data.last_page;
        toRegister.value = res.data.to;
        fromRegister.value = res.data.from;

        totalRegisters.value = res.data.total;
      } catch (e) {
        loading.value = false;
      }
    };

    const getStatusesOrder = async () => {
      const { data: res } = await utilitiesService.getStatusesOrder();
      statuses.value = res;
      copy_statuses.value = [...res];
      copy_statuses.value.unshift({
        name: "Todas",
        id: ""
      });
      copy_statuses.value.push({
        name: "No finalizadas",
        id: "no_finished"
      });
    };

    const formOrderAction = data => {
      if (data.type == "created") items.value.unshift(data.data);
      if (data.type == "updated") {
        const index = items.value.findIndex(i => i.id == data.data.id);
        items.value[index] = data.data;
        items.value = [...items.value];
      }
      showAddOrderModal.value = false;
    };

    //Ajax search select client
    const onSearch = async (search, loading) => {
      try {
        const { data: res } = await clientService.getClients(1, {
          perPage: 10,
          term: search
        });

        clients.value = res.data.data;
      } catch (error) {
        toast({
          component: ToastificationContent,
          props: {
            title: error.message,
            icon: "CheckIcon",
            variant: "warning"
          }
        });
      }
    };

    const openPaymentForm = order => {
      orderPayment.value = order;
      showPaymentForm.value = true;
    };

    const openModalAddonForm = order => {
      orderPayment.value = order;
      modalAddAddon.value = true;
    };

    const viewAddons = order => {
      orderPayment.value = JSON.parse(JSON.stringify(order));
      modalViewAddons.value = true;
    }

    const eventToOrder = data => {
      showPaymentForm.value = false;
      modalAddAddon.value = false;
      const index = items.value.findIndex(i => i.id == data.id);
      items.value[index].due = data.due;
      items.value[index].total_paid = data.total_paid;
      items.value[index].additional_total = data.additional_total;
      items.value[index].additional_features = data.additional_features;
      items.value = JSON.parse(JSON.stringify(items.value));
    };

    const { sortBy, isSortDirDesc, refInvoiceListTable } = useInvoicesList();

    const getColorStatus = code_status => {
      if (code_status == "in_process") return "info";
      if (code_status == "in_route") return "warning";
      if (code_status == "in_destiny") return "warning";
      if (code_status == "delivered") return "success";
      if (code_status == "finished") return "primary";
      if (code_status == "cancelled") return "danger";
    };

    const disabledActionsToOrder = code_status => {
      const status_for_disabled_action = ["finished", "cancelled"];
      if (status_for_disabled_action.includes(code_status)) {
        return true;
      } else {
        return false;
      }
    };

    const getCurrentMoneyInDistributor = async (distributorId) => {
      try {
        loading_money_distributor.value = true
        const { data: res } = await orderService.getCurrentMoneyInDistributor(
          distributorId
        );
        money_distributor.value = res.data;
      } catch (error) {
        toast({
          component: ToastificationContent,
          props: {
            title: error.message,
            icon: "CheckIcon",
            variant: "warning"
          }
        });
      }
      loading_money_distributor.value = false
    }

    const updateAddons = (data) => {
      get()
    }

    //Wachers
    watch(currentPage, (currentPage, prevCurrentPage) => {
      get(currentPage, {
        term: search.value
      });
    });

    watch(perPage, currentPerPage => {
      toRegister.value = currentPerPage;
      get(1, {
        term: search.value
      });
    });

    watch(distributorFilter, currentDistributorFilter => {
      distributorFilter.value = currentDistributorFilter;
      get(1, {
        term: search.value
      });
    });

    watch(statusFilter, currentStatusFilter => {
      statusFilter.value = currentStatusFilter;
      get(1, {
        term: search.value
      });
    });

    watch(rangePicker, newVal => {
      if (newVal.length == 2) {
        get(1, {
          term: search.value
        });
      }
    });

    watch(search, async (search, prevSearch) => {
      if (currentPage.value === 1) {
        await get(currentPage.value, {
          term: search
        });
      } else {
        currentPage.value = 1;
      }
    });

    watch(modalAddAddon, async (newval) => {
      if (newval) {
        if (orderPayment.value.distributor) {
          await getCurrentMoneyInDistributor(
            orderPayment.value.distributor.user_id
          )
        }
      }
    })

    const echo = () => {
      const echo = new Echo({
        broadcaster: "socket.io",
        host: config.socketUrl,
        auth: {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`
          }
        }
      });

      echo
        .channel(`user.${store.state.app.user.id}`)
        .listen(".new-order", () => {
          toast({
            component: ToastificationContent,
            props: {
              title: "Nueva orden registrada",
              icon: "CheckIcon",
              variant: "success"
            }
          });
          get();
        })
        .listen(".change-status-order", data => {
          const index = items.value.findIndex(i => i.id == data.order.id);
          if (index != -1) {
            items.value[index].order_latest_status =
              data.order.order_latest_status;
            items.value = JSON.parse(JSON.stringify(items.value));
            toast({
              component: ToastificationContent,
              props: {
                title: `La orden ${data.order.code} ha cambiado a ${data.order.order_latest_status.name}`,
                icon: "CheckIcon",
                variant: "success"
              }
            });
          }
        });
    };

    //Mounted
    onMounted(() => {
      get();

      setTimeout(() => {
        echo();
      }, 5000);

      getStatusesOrder();
    });

    onUnmounted(() => {
      // clearInterval(refreshInterval.value)
    });

    return {
      modalViewAddons,
      loading_money_distributor,
      money_distributor,
      disabledActionsToOrder,
      getColorStatus,
      viewAddons,
      updateAddons,
      modalAddAddon,
      openModalAddonForm,
      showPaymentForm,
      openPaymentForm,
      eventToOrder,
      orderPayment,
      formatCurrency,
      srcPdfReceipt,
      printOrderReceipt,
      loadingPdfReceipt,
      removeDistributorToOrder,
      assignDistributorToOrder,
      loading_assign_distributor,
      modalAddDistributor,
      addDistributor,
      distributorSelectForAsign,
      loading_option_order,
      formOrderAction,
      flagUpdateAction,
      loading_btn_change_status,
      changeStatusOrder,
      statusFilter,
      get,
      tableColumns,
      perPage,
      currentPage,
      totalRegisters,
      perPageOptions,
      search,
      sortBy,
      isSortDirDesc,
      refInvoiceListTable,
      items,
      fromRegister,
      toRegister,
      orderData,
      showAddOrderModal,
      editOrder,
      createOrder,
      deleteOrder,
      clients,
      onSearch,
      dateFormat,
      loading_btn,
      loading,
      distributorsFilters,
      distributorFilter,
      statuses,
      copy_statuses,
      rangePicker
    };
  }
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
