import api from "@/api";

class PaymentService {
  static getPaymentMethods() {
    return api({
      url: `payment-methods`
    });
  }

  static storePayment(data) {
    return api({
      url: "store-payment",
      data,
      method: "post"
    });
  }

  static storePaymentAll(data) {
    return api({
      url: "store-payment-all",
      data,
      method: "post"
    });
  }
}

export default PaymentService;
